import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import { withTranslation, TFunction } from "react-i18next";
import {
  StyledContainer,
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Span,
  StyledRow,
  NoShadowButton,
  CustomImg,
  Outline,
  CloseIcon,
  DropdownMenu,
  ModalOverlay,
  MainContentWrapper,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const MenuItem = () => {
    const history = useHistory();

    const navigateTo = (path: string) => {
      history.push(path);
      setDropdownVisible(false);
    };

    return (
      <>
        <CustomNavLinkSmall onClick={() => navigateTo("/about")}>
          <Span>{t("About Us")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigateTo("/features")}>
          <Span>{t("Features")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigateTo("/blog")}>
          <Span>{t("Blog")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigateTo("/support")}>
          <Span>{t("Support")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ width: "180px" }}>
          <Link to="surveyBlock" smooth={true} duration={500} offset={400}>
            <Span>
              <NoShadowButton>{t("Contact")}</NoShadowButton>
            </Span>
          </Link>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <>
      <HeaderSection>
        <StyledContainer>
          <StyledRow justify="center">
            <LogoContainer to="/" aria-label="homepage">
              <CustomImg src="/FocaLogoWord.png" alt="logo" />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={toggleDropdown}>
              {dropdownVisible ? <CloseIcon /> : <Outline />}
            </Burger>
          </StyledRow>
        </StyledContainer>
      </HeaderSection>

      {dropdownVisible && (
        <>
          <ModalOverlay onClick={closeDropdown} />
          <DropdownMenu>
            <MenuItem />
          </DropdownMenu>
        </>
      )}

      <MainContentWrapper dropdownVisible={dropdownVisible}>
      </MainContentWrapper>
    </>
  );
};

export default withTranslation()(Header);
