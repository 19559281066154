const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: '/about',
    exact: true,
    component: 'About',
  },
  {
    path: '/features',
    exact: true,
    component: 'Features',
  },
  {
    path: '/blog',
    exact: true,
    component: 'Coming',
  },
  {
    path: '/support',
    exact: true,
    component: 'Support',
  },
];

export default routes;
